<script setup lang="ts">
import { mdiChevronRight } from '@mdi/js';

interface Listing {
  title: string;
  link: string;
}

const props = defineProps<{
  category?: Category;
  listing?: Listing;
}>();

const type = computed(() => (props.category ? 'category' : 'listing'));
</script>

<template>
  <li :class="`category-list-item--${type}`">
    <NuxtLink
      v-if="listing"
      class="category-list-item__link"
      :to="listing.link"
    >
      <div class="category-list-item__label">{{ listing.title }}</div>
      <div class="category-list-item__arrow">
        <BaseSvgIcon type="mdi" :is="mdiChevronRight" />
      </div>
    </NuxtLink>
    <NuxtLink
      v-else
      class="category-list-item__link"
      :to="{ name: 'category', params: { category: category.slug } }"
    >
      <BaseSvgIcon
        v-if="category.icon"
        :html="category.icon"
        class="category-list-item__thumbnail"
        width="54"
        height="45"
        viewbox="0 0 60 50"
      />
      <div class="category-list-item__label">{{ category.name }}</div>
      <div class="category-list-item__arrow">
        <BaseSvgIcon type="mdi" :is="mdiChevronRight" />
      </div>
    </NuxtLink>
  </li>
</template>

<style scoped lang="scss">
.category-list-item {
  $this: &;

  @include box-shadow;

  background: color(neutral, 200);

  list-style-type: none;
  &--category,
  &--listing {
    @extend #{$this};
  }
  &__link {
    text-decoration: none;

    color: color(neutral, 750);

    min-height: 72px;
    padding: 16px 25px;

    display: flex;

    align-items: center;
  }
  &__thumbnail {
    height: 50px;
    margin-right: 16px;

    overflow: hidden;

    flex: 0 0 60px;
  }
  &__label {
    font-weight: $medium;

    flex: 1;
  }
}
</style>
