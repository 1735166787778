<script setup lang="ts">
const { $buildClass } = useNuxtApp();

const { logPageView } = useAnalytics();

const title = 'Home';

useMeta();

logPageView(title);

const version = useEnv('APP_VERSION');
const ssr = useEnv('SSR');
</script>

<template>
  <ThePage :class="$buildClass()">
    <NuxtLayout>
      <template #full>
        <HayFeature
          background-image="https://media.haystack.ie/cdn-cgi/image/fit=cover,format=auto/search.png"
        >
          <SearchForm :class="$buildClass('search')" />
        </HayFeature>
      </template>
      <CategoryList :class="$buildClass('categories')" />
      <PageSection title="Recent Ads" :class="$buildClass('recent')">
        <RecentPosts />
      </PageSection>
    </NuxtLayout>
    <p class="version-number">
      Haystack v.{{ version }}
      <template v-if="ssr">[SSR]</template>
    </p>
  </ThePage>
</template>

<style scoped lang="scss">
.index-page {
  background: color(neutral, 250);

  padding-bottom: 40px;

  --content-top-margin: -160px;
  &__search {
    height: 450px;
    padding-bottom: 160px;
  }
  &__categories {
    margin: 0 auto;
  }
  &__recent {
    margin-top: 32px;
  }

  .version-number {
    color: color(neutral, 550);
    text-align: center;
    font-size: 0.75rem;
  }
}
</style>
