<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';

const { $queries } = useNuxtApp();

const { data: categories, suspense: categoriesSuspense } = useQuery(
  $queries().categories.all()
);

onServerPrefetch(async () => {
  await categoriesSuspense();
});
</script>

<template>
  <ul class="category-list">
    <CategoryListItem
      :listing="{ title: 'View all adverts', link: '/for-sale' }"
    />
    <CategoryListItem
      v-for="category in categories"
      :key="category.slug"
      :category="category"
    />
  </ul>
</template>

<style scoped lang="scss">
.category-list {
  @include media-breakpoint-up(sm) {
    column-gap: 1px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  background: color(neutral, 250);

  margin: 0;
  padding: 0;

  display: grid;

  list-style-type: none;

  row-gap: 1px;
  & > * {
    &:first-child {
      grid-column: 1 / -1;
    }
  }
}
</style>
