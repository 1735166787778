<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { mdiMagnify, mdiChevronDown } from '@mdi/js';

const { $queries } = useNuxtApp();
const { data: counties, suspense: countiesSuspense } = useQuery(
  $queries().counties.all()
);

const router = useRouter();

const terms = ref('');
const county = ref<string | null>(null);

const { logEvent } = useAnalytics();

const search = () => {
  logEvent('search', {
    ...(terms.value.length && { search_term: toValue(terms) }),
    ...(county.value && { search_county: toValue(county) }),
  });

  router.push({
    name: 'search',
    query: {
      ...(terms.value.length && { terms: terms.value }),
      ...(county.value && { county: county.value }),
    },
  });
};

onServerPrefetch(async () => {
  await countiesSuspense();
});
</script>

<template>
  <section class="search-form">
    <div class="search-form__form">
      <div class="search-form__row">
        <div class="search-form__field">
          <label
            class="search-form__label search-form__label--hidden"
            for="query"
          >
            Query
          </label>
          <div class="search-form__input--icon-start">
            <div class="search-form__icon">
              <BaseSvgIcon :is="mdiMagnify" type="mdi" />
            </div>
            <input
              id="terms"
              v-model="terms"
              type="text"
              name="terms"
              placeholder="Search"
              @keydown.enter="search"
            />
          </div>
        </div>
      </div>
      <div class="search-form__row">
        <div class="search-form__field">
          <label
            class="search-form__label search-form__label--hidden"
            for="query"
          >
            Location
          </label>
          <div class="search-form__input--icon-end">
            <div class="search-form__icon">
              <BaseSvgIcon :is="mdiChevronDown" type="mdi" />
            </div>
            <select
              id="county"
              v-model="county"
              name="name"
              @keydown.enter="search"
            >
              <option :value="null" selected>All counties...</option>
              <option
                v-for="county in counties"
                :key="county.slug"
                :value="county.slug"
              >
                {{ county.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="search-form__row">
        <HayFormButton name="search" @click="search">Search</HayFormButton>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.search-form {
  $this: &;

  display: flex;
  flex-direction: column;

  justify-content: center;
  --button-border-radius: 8px;
  --button-background-color: #{color(neutral, 800)};
  --button-color: #{color(neutral, 100)};
  --button-font-weight: 500;
  --button-font-size: 20px;
  --row-spacing: 16px;

  &__form {
    margin: 0 auto;
    max-width: 454px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: column;
    & + & {
      margin-top: 16px;
    }
  }
  &__field {
    overflow: hidden;
    border-radius: 8px;
  }
  &__label {
    &--hidden {
      @include visually-hidden;
    }
  }
  &__input {
    background-color: #ffffff;
    color: #4f645d;

    position: relative;
    input,
    select {
      background: none;
      border: none;
      color: #4f645d;
      outline: none !important;

      height: 52px;
      padding: 0 22px;

      position: relative;

      font-size: 18px;
      line-height: 48px;
    }
    input {
      width: 100%;

      flex: 1;
    }
    select {
      width: 100%;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    &--icon-start,
    &--icon-end {
      @extend #{$this}__input;
    }
    &--icon-start {
      input,
      select {
        padding-left: calc(52px + 16px);
      }
      #{$this}__icon {
        left: 0;
      }
    }
    &--icon-end {
      input,
      select {
        padding-right: calc(52px + 16px);
      }
      #{$this}__icon {
        right: 0;
      }
    }
  }
  &__icon {
    transform: translateY(-50%);

    background: #eeeeee;

    height: 52px;
    width: 52px;

    display: flex;

    position: absolute;
    top: 50%;

    justify-content: center;
    align-items: center;
  }
}
</style>
